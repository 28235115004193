import Nav from './components/nav';
import Home from './controllers/home';
import Page from './controllers/page';


document.addEventListener('DOMContentLoaded', () => {
    new Nav();
    // new Ymk();
    
    const page = new Page();
    document.pageinstance = page;
});